<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div v-show="add">
        <app-collapse>
          <app-collapse-item isVisible title="Ajouter un membre">
            <!-- form start -->
            <validation-observer
              ref="addForm"
              class="d-flex justify-content-center"
            >
              <b-form class="col mt-2" @submit.prevent>
                <b-row>
                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="addMembre.name"
                        placeholder="Nom et Prénom"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        @input="nameInputReset"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : nameErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <label for="phone">Téléphone</label>
                      <b-form-input
                        id="phone"
                        v-model="addMembre.phone"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        placeholder="Téléphone"
                        @input="phoneInputReset"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : phoneErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="work"
                      rules="required"
                    >
                      <label for="work">Poste</label>
                      <!-- <combo-box-component
                        id="work"
                        :dataSource="worksList"
                        v-model="addMembre.work"
                        @change="workSelectReset"
                      >
                      </combo-box-component> -->

                      <b-form-input
                        list="works"
                        id="wrk"
                        v-model="addMembre.work"
                        @change="workSelectReset"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : worksErrors
                      }}</small>
                      <b-form-datalist id="works" :options="worksList">
                      </b-form-datalist>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="date_embauche"
                      rules="required"
                    >
                      <label for="reccm">Date d'embauche</label>
                      <b-form-input
                      v-model="addMembre.hiring_date"
                      :date-format-options="dateFormatOptions"
                      type="date"
                      class="d-inline-block"
                      /> 
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : hiringDateErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="salary"
                      rules="required"
                    >
                      <label for="salary">Salaire</label>
                      <cleave
                        id="salary"
                        v-model="addMembre.salary"
                        :state="errors.length > 0 ? false : null"
                        @input="salaryInputReset"
                        placeholder="0"
                        class="form-control"
                        :raw="false"
                        :options="numberFormat"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : salaryErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <!-- submit button start -->
                <div class="mt-2">
                  <b-button
                    class="mr-2"
                    variant="outline-secondary"
                    type="reset"
                    @click="resetInput"
                  >
                    Annuler
                  </b-button>

                  <b-button
                    v-if="etatButton"
                    variant="primary"
                    type="submit"
                    :disabled="isOperating"
                    @click.prevent="addMembres"
                  >
                    Enregistrer
                  </b-button>

                  <b-button v-else variant="primary">
                    <b-icon-arrow-clockwise
                      animation="spin"
                      font-scale="1"
                    ></b-icon-arrow-clockwise>
                    En cours
                  </b-button>
                </div>
                <!-- submit button end -->
              </b-form>
            </validation-observer>
            <!-- form end -->
          </app-collapse-item>
        </app-collapse>
      </div>

      <div :hidden="hidden">
        <app-collapse>
          <app-collapse-item ref="collapse" title="Ajouter un membre">
            <!-- form start -->
            <validation-observer
              ref="addForm"
              class="d-flex justify-content-center"
            >
              <b-form class="col mt-2" @submit.prevent>
                <b-row>
                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="addMembre.name"
                        placeholder="Nom et Prénom"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        @input="nameInputReset"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : nameErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <label for="phone">Téléphone</label>
                      <b-form-input
                        id="phone"
                        v-model="addMembre.phone"
                        placeholder="Téléphone"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        @input="phoneInputReset"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : phoneErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="work"
                      rules="required"
                    >
                      <label for="work">Poste</label>
                      <!-- <combo-box-component
                        id="work"
                        :dataSource="worksList"
                        v-model="addMembre.work"
                        @change="workSelectReset"
                      >
                      </combo-box-component> -->

                      <b-form-input
                        list="works"
                        id="wrk"
                        v-model="addMembre.work"
                        @change="workSelectReset"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : worksErrors
                      }}</small>
                      <b-form-datalist id="works" :options="worksList">
                      </b-form-datalist>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="date_embauche"
                      rules="required"
                    >
                      <label for="reccm">Date d'embauche</label>
                      <b-form-datepicker
                        v-model="addMembre.hiring_date"
                        placeholder="Date"
                        labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                        labelNoDateSelected="Aucune date sélectionnée"
                        labelResetButton="Annuler"
                        reset-button
                        :date-format-options="dateFormatOptions"
                        locale="fr"
                        @input="hiringDateReset"
                      ></b-form-datepicker>
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : hiringDateErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="salary"
                      rules="required"
                    >
                      <label for="salary">Salaire</label>
                      <cleave
                        id="salary"
                        v-model="addMembre.salary"
                        :state="errors.length > 0 ? false : null"
                        @input="salaryInputReset"
                        placeholder="0"
                        class="form-control"
                        :raw="false"
                        :options="numberFormat"
                      />
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : salaryErrors
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <!-- submit button start -->
                <div class="mt-2">
                  <b-button
                    class="mr-2"
                    variant="outline-secondary"
                    type="reset"
                    @click="resetInput"
                  >
                    Annuler
                  </b-button>

                  <b-button
                    v-if="etatButton"
                    variant="primary"
                    type="submit"
                    :disabled="isOperating"
                    @click.prevent="addMembres"
                  >
                    Enregistrer
                  </b-button>

                  <b-button v-else variant="primary">
                    <b-icon-arrow-clockwise
                      animation="spin"
                      font-scale="1"
                    ></b-icon-arrow-clockwise>
                    En cours
                  </b-button>
                </div>
                <!-- submit button end -->
              </b-form>
            </validation-observer>
            <!-- form end -->
          </app-collapse-item>
        </app-collapse>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-2 row">
            <!-- search start -->
            <div class="mr-4">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Recherche"
                @input="filterValue()"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- Post list start -->
            <b-form-select
              v-model="selectedPoste"
              :options="optionsPoste"
              class="col-3"
              value-field="text"
              text-field="text"
              @input="filterValue()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                >
                  -- Poste --
                </b-form-select-option>
              </template>
            </b-form-select>
            <!-- Post list end -->
          </div>

          <!-- table start -->
          <b-table
            id="my-table"
            responsive
            :fields="headers"
            :items="membres"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
          >
            <template #cell(name)="data">
              <span>
                {{ data.item.name ? data.item.name : "----" }}
              </span>
            </template>

            <template #cell(work)="data">
              <span>
                {{ data.item.work ? data.item.work : "----" }}
              </span>
            </template>

            <template #cell(phone)="data">
              <span>
                {{ data.item.phone ? data.item.phone : "----" }}
              </span>
            </template>

            <template #cell(hiring_date)="data">
              <span>
                {{ data.item.hiring_date ? ($formatDate(data.item.hiring_date)) : "----" }}
              </span>
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div>
                  <b-button
                    :id="'button-3' + data.item.id"
                    size="sm"
                    variant="flat-dark"
                    @click="detailsMembre(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    placement="left"
                    :target="'button-3' + data.item.id"
                    title="Détails"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
                <div>
                  <b-button
                    :id="'button-1' + data.item.id"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="editPoste(data.item)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <!-- <b-tooltip
                    placement="left"
                    :target="'button-1' + data.item.id"
                    title="Editer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>

                <div>
                  <b-button
                    :id="'button-2' + data.item.id"
                    size="sm"
                    variant="flat-dark"
                    @click="congesMembre(data.item)"
                  >
                    <feather-icon icon="UserPlusIcon" />
                  </b-button>
                  <b-tooltip
                    placement="left"
                    :target="'button-2' + data.item.id"
                    title="Congés"
                    variant="dark"
                  ></b-tooltip>
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          id="modal-conges"
          v-model="modalConges"
          :title="'CONGÉS : ' + nameMembre"
          ok-only
          ok-title="OKAY"
          ok-variant="outline-primary"
          @hidden="resetModal"
          centered
        >
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>N°</b-th>
                <b-th>ANNEE</b-th>
                <b-th>NOMBRE DE CONGES</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, index) in listeConges" :key="index">
                <b-td>{{ index + 1 }}</b-td>
                <b-td>{{ item.year }}</b-td>
                <b-td>{{ item.total_days }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-modal>
        <!-- modal edit end-->

        <!-- modal details start  -->
        <b-modal
          id="modal-details"
          v-model="modalDetails"
          title="DÉTAILS MEMBRE"
          ok-only
          ok-title="OKAY"
          ok-variant="outline-primary"
          @hidden="detailsResetModal"
          centered
        >
          <!-- <b-card class="bg-color"> -->
          <div class="mt-2">
            <div class="mb-2 bg-color py-1">
              <span class="ml-5 mr-4"><b>SALAIRE ACTUEL : </b></span>
              <span class="ml-5"
                >{{
                  details.salary ? thousandSeparator(details.salary) : 0
                }}
                XOF</span
              >
            </div>
            <div class="mb-2 bg-color py-1">
              <span class="ml-5 mr-4"><b>CREDIT ACTUEL : </b></span>
              <span class="ml-5"
                >{{
                  details.debt ? thousandSeparator(details.debt) : 0
                }}
                XOF</span
              >
            </div>
          </div>
          <!-- </b-card> -->
        </b-modal>
        <!-- modal details end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="etatAdd">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
// import Select2 from 'v-select2-component'
import vSelect from "vue-select";
// import Combobox from "vue-combobox";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import "vue-select/dist/vue-select.css";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPlus,
  BIconPencilFill,
  BIconTrashFill,
  BIconArrowClockwise,
  BIconCircleFill,
  BFormDatepicker,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BCol,
  BTooltip,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormDatalist,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    Cleave,
    BCard,
    BTable,
    BModal,
    BTooltip,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconArrowClockwise,
    BIconCircleFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    // Combobox,
    ComboBoxPlugin,
    ComboBoxComponent,
    BRow,
    BCol,
    vSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormGroup,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BFormDatalist,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      optionsPoste: [],
      worksList: [],
      selectedPoste: "",
      etatSubmit: true,
      etatButton: true,
      nameMembre: "",
      n: 1,
      listeConges: [],
      headersConges: [
        {
          key: "N",
          label: "N°",
          sortable: true,
        },
        {
          key: "year",
          label: "Année",
          sortable: true,
        },
        {
          key: "total_days",
          label: "Nombre",
          sortable: true,
        },
      ],
      modalConges: false,
      modalDetails: false,
      add: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      isOperating: false,
      required,
      integer,
      alpha,
      allRows: 0,
      alphaDash,
      // totalRows: 1,
      perPage: 5,
      currentPage: 1,
      filter: null,
      pageOptions: [],
      headers: [
        {
          key: "name",
          label: "Nom & Prénom",
          sortable: true,
        },
        {
          key: "work",
          label: "Poste",
          sortable: true,
        },
        {
          key: "phone",
          label: "Téléphone",
          sortable: true,
        },
        {
          key: "hiring_date",
          label: "Date d'embauche",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "w-25",
        },
      ],
      membres: [],
      addMembre: {
        id: "",
        name: "",
        phone: "",
        work: "",
        salary: "",
        hiring_date: "",
      },
      nameErrors: null,
      phoneErrors: null,
      worksErrors: null,
      salaryErrors: null,
      hiringDateErrors: null,
      details: {
        salary: "",
        debt: "",
      },
      valueSearch: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    totalRows() {
      this.pageOptions = [5, 10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    valid: function () {
      return this.departureCountry !== null && this.arrivalCountry !== null;
    },

    validationMessage: function () {
      return this.departureCountry === null
        ? "Please select a Departure Country first!"
        : "Please select an Arrival Country";
    },
  },
  created() {
    this.getMembres();
    this.getPostes();
  },
  methods: {
    filterValue(val) {
      this.filter = " ";
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    filterFunction(row) {
      if (
        this.selectedPoste != null &&
        this.selectedPoste != "" &&
        this.selectedPoste != row.work
      ) {
        return false;
      } else if (
        row.name.toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.work
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.phone.toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.hiring_date.toLowerCase().indexOf(this.valueSearch.toLowerCase()) ==
          -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    // select2() {
    //   $("#region").select2({
    //     language: "fr",

    //     tags: true,
    //   });
    // },

    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    thousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    getPostes() {
      this.$http
        .get("/gestion-personnel/fonctions")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj1 = {
              id: element.id,
              text: element.name,
            };

            var obj2 = {
              value: element.id,
              text: element.name,
            };

            this.worksList.push(obj1);
            this.optionsPoste.push(obj2);
          });
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    getMembres() {
      this.$http
        .get("/gestion-personnel/personnels")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

           this.membres = res.data.data.reverse();

          if (this.membres.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          
          this.allRows = this.membres.length

        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    // congés membre start
    congesMembre(item) {
      this.nameMembre = item.name.toUpperCase();
      this.listeConges = item.conges;
      this.modalConges = true;
    },

    resetModal() {
      this.n = 1;
    },
    // congés membre end

    // details membre start
    detailsMembre(item) {
      this.details.salary = item.salary;
      this.details.debt = item.debt;
      this.modalDetails = true;
    },

    detailsResetModal() {
      this.details.salary = "";
      this.details.debt = "";
    },
    // details membre end

    // add membres start
    editPoste(item) {
      if (!this.addMembre.length) {
        this.addMembre = Object.assign({}, item);
      }

      this.etatSubmit = false;
      this.addMembre.id = item.id;
      this.addMembre.name = item.name;
      this.addMembre.phone = item.phone;
      this.addMembre.work = item.work;
      this.addMembre.salary = item.salary ? item.salary : 0;
      this.addMembre.hiring_date = item.hiring_date;

      this.$refs.collapse.updateVisible(true);
    },

    addMembres() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          if (this.etatSubmit) {
            this.etatButton = false;
            this.addMembre.salary = this.$thousandFormater(
              this.addMembre.salary
            );
            this.$http
              .post("gestion-personnel/personnels", this.addMembre)
              .then((result) => {
                // 	this.isOperating = false;
                if (result.data.success) {
                  this.etatButton = true;
                  this.resetInput();
                  this.addMembre = JSON.parse(
                    JSON.stringify({ type: Object, default: () => {} })
                  );
                  this.add = false;
                  this.hidden = false;
                  this.$refs.addForm.reset();
                  this.showToast("Membre ajouté avec succès", "success");
                  if (this.membres.length) {
                    this.$refs.collapse.updateVisible(false);
                  }
                  this.getMembres();
                } else {
                  this.etatButton = true;
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((e) => {
                this.etatButton = true;
                var err = e.response.data.errors;
                // this.isOperating = false;

                if (err) {
                  if (err.name) {
                    this.nameErrors = err.name[0];
                  }

                  if (err.phone) {
                    this.phoneErrors = err.phone[0];
                  }

                  if (err.work) {
                    this.worksErrors = err.work[0];
                  }

                  if (err.salary) {
                    this.salaryErrors = err.salary[0];
                  }

                  if (err.hiring_date) {
                    this.hiring_dateErrors = err.hiring_date[0];
                  }
                }
              });
          } else {
            this.etatButton = false;
            this.addMembre.salary = this.$thousandFormater(
              this.addMembre.salary
            );
            this.$http
              .put(
                "gestion-personnel/personnels/" + this.addMembre.id,
                this.addMembre
              )
              .then((result) => {
                // 	this.isOperating = false;
                if (result.data.success) {
                  this.etatButton = true;
                  this.resetInput();
                  this.addMembre = JSON.parse(
                    JSON.stringify({ type: Object, default: () => {} })
                  );
                  this.add = false;
                  this.hidden = false;
                  this.showToast("Membre ajouté avec succès", "success");
                  this.$refs.addForm.reset();
                  if (this.membres.length) {
                    this.$refs.collapse.updateVisible(false);
                  }
                  this.getMembres();
                } else {
                  this.etatButton = true;
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((e) => {
                this.etatButton = true;
                var err = e.response.data.errors;
                // this.isOperating = false;

                if (err) {
                  if (err.name) {
                    this.nameErrors = err.name[0];
                  }

                  if (err.phone) {
                    this.phoneErrors = err.phone[0];
                  }

                  if (err.work) {
                    this.worksErrors = err.work[0];
                  }

                  if (err.salary) {
                    this.salaryErrors = err.salary[0];
                  }

                  if (err.hiring_date) {
                    this.hiring_dateErrors = err.hiring_date[0];
                  }
                }
              });
          }
        }
      });
    },

    resetInput() {
      this.etatSubmit = true;
      this.addMembre.name = "";
      this.addMembre.phone = "";
      this.addMembre.work = "";
      this.addMembre.salary = "";
      this.addMembre.hiring_date = "";
      this.nameInputReset();
      this.phoneInputReset();
      this.workSelectReset();
      this.salaryInputReset();
      this.hiringDateReset();
    },

    nameInputReset() {
      if (this.nameErrors) {
        this.nameErrors = null;
      }
    },

    phoneInputReset() {
      if (this.phoneErrors) {
        this.phoneErrors = null;
      }
    },

    workSelectReset() {
      if (this.worksErrors) {
        this.worksErrors = null;
      }
    },

    salaryInputReset() {
      if (this.salaryErrors) {
        this.salaryErrors = null;
      }
    },

    hiringDateReset() {
      if (this.hiringDateErrors) {
        this.hiringDateErrors = null;
      }
    },
    // add membres end
  },
};
</script>

<style lang='css'>
/* @import '@core/scss/vue/libs/vue-select.scss';
	@import "vue-select/src/scss/vue-select.scss"; */

@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group {
  border-color: rgb(0 0 0 / 15%) !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  color: rgb(127 125 139) !important;
  line-height: 2.1 !important;
  padding-left: 15px !important;
}

.e-input-group::before,
.e-input-group::after {
  background: #e3165b00 !important;
}

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.bg-color {
  background-color: #70707040;
}
</style>
